import React from 'react';
import Home from './Home';



function App() {
  return (
    <div className="App">
      <React.Fragment>
        <Home />
      </React.Fragment>
    </div>
  );
}

export default App;
