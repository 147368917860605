import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Typography from '../components/Typography';


const styles = (theme) => ({
  root: {
    display: 'flex',
    overflow: 'hidden',
    backgroundColor: theme.palette.secondary.light,
  },
  container: {
    marginTop: theme.spacing(15),
    marginBottom: theme.spacing(15),
    display: 'flex',
    position: 'relative',
  },
  item: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(0, 4),
  },
  image: {
    height: 55,
  },
  title: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  curvyLines: {
    pointerEvents: 'none',
    position: 'absolute',
    top: -180,
  },
});

function ProductValues(props) {
  const { classes } = props;

  return (
    <section className={classes.root}>

      <Container className={classes.container}>
        <img
          src="productCurvyLines.png"
          className={classes.curvyLines}
          alt="curvy lines"
        />
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <div className={classes.item}>
              <Typography variant="h4" className={classes.title}>
                Managed Services
              </Typography>
            </div>
          </Grid>


          <Grid item xs={12} md={4}>
            <div className={classes.item}>
              <img
                className={classes.image}
                src="productValues1.svg"
                alt="suitcase"
              />
              <Typography variant="h6" className={classes.title}>
                Reporting & Analytics
              </Typography>
              <Typography variant="h5">
                {'Most businesses realize that their reporting is outdated, but do very little to correct it. Reason? Perception that it’s too difficult, they have too little time; and it’s too complicated. But when you put reporting on the back burner, you put your business at risk. Leave the task of juggling data from multiple systems to us. By understanding your data requirement needs, we will design the best possible solution that is catered and customized for you. We are your Reporting & Analytics partners to help your data to improve accuracy, eliminate manual data gathering, increase collaboration; and finally interpret the data - Data that is meaningful, timely; and that tells a story. Data that enables you to take correct strategic decisions. Data that helps your organization understand current challenges so you can overcome them in order to gain desired results. Ready to take action? Reach out to our Reporting and Analytics experts to provide you the right solution.'}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} md={4}>
            <div className={classes.item}>
              <img
                className={classes.image}
                src="productValues2.svg"
                alt="graph"
              />
              <Typography variant="h6" className={classes.title}>
                Process Improvement
              </Typography>
              <Typography variant="h5">
                {'Every process can be improved – the first step is to realize it needs improvement. Business Analysis is not just a research of identifying business gaps and needs, it’s not just determining solutions to current business issues; and most importantly, not just a software and systems development component. Business Analysis is Process Improvement. It’s a task that understands current challenges and introduces solutions to eliminate those challenges. It’s a strategic planning and policy development. Be it your planning and forecasting tools, or your budgeting calculators, or your master data management databases - don’t punish your system’s error or mistakes, instead work to prevent them from happening again. We are your strategic partners; bring us to the table to understand gaps on your existing processes so we can bring a different point of view on how to manage them better. If you’ve reached that first step, i.e., realized your process needs improvement - Reach out to our Process Improvement experts to provide you the right solution.'}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} md={4}>
            <div className={classes.item}>
              <img
                className={classes.image}
                src="productValues3.svg"
                alt="clock"
              />
              <Typography variant="h6" className={classes.title}>
                Application Development
              </Typography>
              <Typography variant="h5">
                {'Be it Mobile, Web or Desktop – we have experts with combined hundreds of thousands of hours experience in building, and enhancing applications. We are your IT partners that can guide you to the right direction, from choosing the correct tools, to building a comprehensive app, at a fraction of cost, without compromising on quality – PERIOD. Be it your B2B and B2C Products, Digital Catalogues, eCommerce Sites and Apps, Interactive Social Portals, Remote eLearning and Educational Platforms, Disaster Recovery Tools, Custom Financial Systems, Time and Expense Portals, or Infrastructure Architecture and Design. Be it custom application development, or revamping your existing legacy applications, if you are ready to make that change - Reach out to our Application Development experts to provide you the right solution. '}
              </Typography>
            </div>
          </Grid>

          <Grid item xs={12} md={6}>
            <div className={classes.item}>
              <img
                className={classes.image}
                src="productValues3.svg"
                alt="clock"
              />
              <Typography variant="h6" className={classes.title}>
                Quality Assurance
              </Typography>
              <Typography variant="h5">
                {'Quality is ‘The Most’ important factor for contribution to long-term revenue and profitability. By producing quality products, you are establishing your space with your customer. But it comes with a cost. Trick to be successful is to produce the right quality, with little cost. We have a team of certified Quality Assurance Analysts that are experts in creating and executing Test Plans, finding bugs, automating Test Scripts and ensuring a bug free application by taking charge of the defect life cycle management. We can produce hundreds of test plans and thousands of scripts within a matter of days – at a fraction of cost. If you are ready to save thousands of dollars without compromising on quality - Reach out to our Quality Assurance experts to provide you the right solution. '}
              </Typography>
            </div>
          </Grid>

          <Grid item xs={12} md={6}>
            <div className={classes.item}>
              <img
                className={classes.image}
                src="productValues3.svg"
                alt="clock"
              />
              <Typography variant="h6" className={classes.title}>
                Staffing: Technical, Finance, Business Development
              </Typography>
              <Typography variant="h5">
                {'Do you have the right skills to help you reach the top? Is your skill set up to date? We all can be ignorant and believe the answer is Yes. But in order to be a Market Leader, we need to continuously be on our toes to ensure our skill set and resources are skilled with the latest technologies. You don’t have to invest in education by teaching your staff, you focus on doing what you do best – run your business. Let us handle finding and delivering the right technical resources (developers, architects, testers), financial resources (product managers, project managers, process managers), and business developers (sales force, marketing leaders) that will help you reach your destination. We all need staffing help – whether we agree or not. Reach out to our Staffing experts to provide you the right resources.'}
              </Typography>
            </div>
          </Grid>

        </Grid>
      </Container>
    </section >
  );
}

ProductValues.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ProductValues);
