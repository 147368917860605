import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Typography from '../components/Typography';

const styles = (theme) => ({
  root: {
    display: 'flex',
    overflow: 'hidden',
    backgroundColor: theme.palette.secondary.light,
  },
  container: {
    marginTop: theme.spacing(15),
    marginBottom: theme.spacing(15),
    display: 'flex',
    position: 'relative',
  },
  item: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(0, 4),
  },
  image: {
    height: 55,
  },
  title: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  curvyLines: {
    pointerEvents: 'none',
    position: 'absolute',
    top: -180,
  },
});

function ProductValues(props) {
  const { classes } = props;

  return (
    <section className={classes.root}>

      <Container className={classes.container}>
        <img
          src="productCurvyLines.png"
          className={classes.curvyLines}
          alt="curvy lines"
        />
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <div className={classes.item}>
              <Typography variant="h4" className={classes.title}>
                Mission Statement: Our five pillars of strength
              </Typography>
            </div>
          </Grid>


          <Grid item xs={12} md={4}>
            <div className={classes.item}>
              <img
                className={classes.image}
                src="productValues1.svg"
                alt="suitcase"
              />
              <Typography variant="h6" className={classes.title}>
                Trusted Business Advisor
              </Typography>
              <Typography variant="h5">
                {'We are not about selling and making pitches. We take interest in you, understand your challenges, ask the right questions and offer you the right solution. Most importantly, we listen.'}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} md={4}>
            <div className={classes.item}>
              <img
                className={classes.image}
                src="productValues2.svg"
                alt="graph"
              />
              <Typography variant="h6" className={classes.title}>
                Innovate
              </Typography>
              <Typography variant="h5">
                {'We NEVER stop innovating, whether it’s'}
                {' Incremental: Increasing customer value while utilizing existing processes'}
                {' Disruptive: Applying new processes over existing processes'}
                {' Architectural: Learning lessons from existing successful processes to different processes'}
                {' Radical: Giving birth to new solutions'}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} md={4}>
            <div className={classes.item}>
              <img
                className={classes.image}
                src="productValues3.svg"
                alt="clock"
              />
              <Typography variant="h6" className={classes.title}>
                Diversity & Inclusiveness
              </Typography>
              <Typography variant="h5">
                {'Diverse teams perform better. Period. It helps us to understand our customers while encouraging innovation and creativity; and overall a happy work place. Our employees are valued and respected, which leads to greater collaboration providing best solutions for our customers. '}
              </Typography>
            </div>
          </Grid>

          <Grid item xs={12} md={6}>
            <div className={classes.item}>
              <img
                className={classes.image}
                src="productValues3.svg"
                alt="clock"
              />
              <Typography variant="h6" className={classes.title}>
                Frugality
              </Typography>
              <Typography variant="h5">
                {'Accomplish more with less. There are no brownie points to increasing headcount or investing in solutions where not needed. Balancing the right solution with right amount of skills and costs provides immaculate value to the end result. '}
              </Typography>
            </div>
          </Grid>

          <Grid item xs={12} md={6}>
            <div className={classes.item}>
              <img
                className={classes.image}
                src="productValues3.svg"
                alt="clock"
              />
              <Typography variant="h6" className={classes.title}>
                Deliver Results
              </Typography>
              <Typography variant="h5">
                {'Deliver highest quality, in a timely fashion, within the budget. '}
              </Typography>
            </div>
          </Grid>

        </Grid>
      </Container>
    </section >
  );
}

ProductValues.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ProductValues);
