import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import Typography from '../components/Typography';

const backgroundImage =
  'Where.jpg?auto=format&fit=crop&w=1400&q=80';


const styles = (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(9),
  },
  button: {
    border: '4px solid currentColor',
    borderRadius: 0,
    height: 'auto',
    padding: theme.spacing(2, 5),
  },
  link: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  buoy: {
    width: 60,
  },
  background: {
    backgroundImage: `url(${backgroundImage})`,
    backgroundColor: '#7fc7d9', // Average color of the background image.
    backgroundPosition: 'center',
  },
});

function ProductSmokingHero(props) {
  const { classes } = props;

  return (
    <Container className={classes.root} component="section">

      <Button className={classes.button}>
        <Typography variant="h4" component="span">
          <a href="mailto:info@pridelinc.com">
            Got any questions? Need help?
          </a>
        </Typography>
      </Button>

      <Typography variant="h4" className={classes.link}>
        Where?
      </Typography>

      <Grid container spacing={5}>
        <Grid item xs={12} md={4}>
          <div className={classes.item}>
            <Typography variant="h5" align="center">
              Pennington, NJ, USA
            </Typography>
            <Typography variant="h5" align="center">
              +1 (732) 397-2596
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12} md={4}>
          <div className={classes.item}>
            <Typography variant="h5" align="center">
              Tracy, CA, USA
              <Typography variant="h5" align="center">
                +1 (925) 315-9125
            </Typography>
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12} md={4}>
          <div className={classes.item}>
            <Typography variant="h5" align="center">
              Delhi, India
            </Typography>
            <Typography variant="h5" align="center">
              Gurugram, India
            </Typography>
          </div>
        </Grid>
      </Grid>
    </Container>
  );
}

ProductSmokingHero.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ProductSmokingHero);
